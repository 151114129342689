import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLogin:boolean = false;
  identityurl: any = 'assets/images/avatar.jpg';
  userName = window.sessionStorage.getItem("username");
  private StorageData: any = {};

  constructor(private Router: Router, public toastr : ToastrService, private sharedService : UserServiceService) { }

  ngOnInit(): void {
    this.isValidLogin();
    this.getUserProfileData();
    if(this.isLogin === false)
    {
      this.goForLogin();
    }
  }
  
  isValidLogin(){
    if(this.userName != null || this.userName != undefined){
      this.isLogin = true;
    }
  }

  getUserProfileData(){
    var userId = window.sessionStorage.getItem("user_id");
    var userProfileId = {id : userId};
    this.sharedService.getUserProfile(userProfileId).subscribe(res=>{
      this.identityurl = res['data']['profile_photo'];
      window.sessionStorage.setItem("profilePhoto",this.identityurl);
    })
  }

  goEditProfile(){
    this.Router.navigate(['edit-profile'])
  }
 
  createAccount(){
    this.Router.navigate(['sign-up'])
  }

  goResetPassword(){
    this.Router.navigate(["/set-password"])
  }
  
  goForLogin(){
    this.Router.navigate(["/account-login"])
  }

  goForLogout()
  {
    this.StorageData = {};
    sessionStorage.clear();
    this.Router.navigate(['account-login'])
  }
}
