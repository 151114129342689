<div class="header">

  <div mat-button [matMenuTriggerFor]="beforeMenu" class="right-content">
    <p *ngIf="!isLogin" class="text-uppercase">User Name</p>
    <p *ngIf="isLogin" class="text-uppercase">{{this.userName}}</p>
    <img [src]="this.identityurl" class="user-profile">
  </div>
  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="createAccount()" *ngIf="!isLogin">
      <div _ngcontent-jxu-c138="" class="d-flex align-items-center">
        <span class="glyphicon glyphicon-edit custom-icon"></span>
        <span _ngcontent-jxu-c138="">SignUp</span>
      </div>
    </button>
    <button mat-menu-item (click)="goEditProfile()" *ngIf="isLogin">
      <div _ngcontent-jxu-c138="" class="d-flex align-items-center">
        <span class="glyphicon glyphicon-edit custom-icon"></span>
        <span _ngcontent-jxu-c138="">Edit Profile</span>
      </div>
    </button>
    <button mat-menu-item (click)="goResetPassword()" *ngIf="isLogin">
      <div _ngcontent-jxu-c138="" class="d-flex align-items-center">
        <i class="glyphicon glyphicon-lock custom-icon">
        </i>
        <span _ngcontent-jxu-c138="">Reset Password</span>
      </div>
    </button>
    <div _ngcontent-soe-c138="" class="dropdown-divider"></div>
    <button mat-menu-item (click)="goForLogin()" *ngIf="!isLogin">
      <div _ngcontent-soe-c138="" class="d-flex align-items-center">
        <span class="glyphicon glyphicon-log-in custom-icon"></span>
        <span _ngcontent-soe-c138="">Login</span>
      </div>
    </button>
    <button mat-menu-item (click)="goForLogout()" *ngIf="isLogin">
      <div _ngcontent-soe-c138="" class="d-flex align-items-center">
        <span class="glyphicon glyphicon-off custom-icon"></span>
        <span _ngcontent-soe-c138="">Logout</span>
      </div>
    </button>

  </mat-menu>
</div>