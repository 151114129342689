import { Component, inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-add-new-contury',
  templateUrl: './add-new-contury.component.html',
  styleUrls: ['./add-new-contury.component.scss']
})
export class AddNewConturyComponent implements OnInit {
  CountryFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  isUpdate:boolean = false;
  countryId  = window.sessionStorage.getItem("country_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) {  }

  ngOnInit(): void {
    this.isUpdateRecord();
    this.formId = {id:this.countryId};
    this.sharedService.getCountryDetail(this.formId).subscribe(res=>{
        
      this.formValues = {
        country_name : res['data']['country_name'],
      };
      this.selectedStatus = res['data']['status'];
      
      console.log(this.formValues);
      
      this.CountryFormGroup.patchValue(this.formValues);

    });

    this.CountryFormGroup = this.fb.group({
    country_id: [this.countryId, Validators.required],
    country_name:['', Validators.required],
    status:['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.countryId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.CountryFormGroup.controls; }

  addCountryData(){
   this.sharedService.addCountry(this.CountryFormGroup.value).subscribe(res=>{
      if(res['meta']['code'] == 1)
      {
          this.TypeSuccess("Record inserted successfully!");
          this.dialog.closeAll();
          window.location.reload();
      }
      else
      {
        this.TypeError(res['meta']['message']);
      }
    })
  }

  updateCountryData()
  {
    this.sharedService.editCountry(this.CountryFormGroup.value).subscribe(res=>{
      if(res['meta']['code'] == 1)
      {
          window.sessionStorage.setItem("country_id",null);
          this.TypeSuccess("Record updated successfully!");
          this.dialog.closeAll();
          window.location.reload();
      }
      else
      {
         this.TypeError(res['meta']['message']);
      }
    })
  }
  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }
  
  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
