
<div class="d-flex align-items-center justify-content-center auth-height full-height-vh m-0 card-content">
  <div class="container custom-control-row card" style="margin: 24px 48px !important; ">
    <br><br>
    <br><br>
    <br><br>
    
    <h3 class="card-title mb-2">Create Account</h3>
    
    <form [formGroup]="registerForm" class="row" >
      <input type="hidden" formControlName="user_id" id="user_id" class="form-control mb-2" />
      <div class="col-lg-6 ">
        <div class="form-group">
          <label>First Name <span class="red">*</span></label>
          <input type="text" formControlName="first_name" id="first_name" class="form-control mb-2" placeholder="First Name" required>
          <div *ngIf=" (rf.first_name.touched) && (rf.first_name.invalid || rf.first_name.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> First Name is
            required</div>
        </div>

        <div class="form-group">
          <label>Last Name <span class="red">*</span></label>
          <input type="text" formControlName="last_name" class="form-control mb-2" placeholder="Last Name" required>
          <div *ngIf=" (rf.last_name.touched) && (rf.last_name.invalid || rf.last_name.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Last Name is
            required</div>
        </div>
        <div class="form-group">
          <label>Email <span class="red">*</span></label>
          <input type="text" autocomplete="off" formControlName="email" class="form-control mb-2" placeholder="Email" required>
          <div *ngIf=" (rf.email.touched) && (rf.email.invalid || rf.email.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Email is
            required</div>

        </div>
        <div class="form-group">
          <label>Username <span class="red">*</span></label>
          <input type="text" autocomplete="off" formControlName="username" class="form-control mb-2" placeholder="Username" required>
         
        </div>

        <div class="form-group" *ngIf="!isLogin">
          <label>Password <span class="red">*</span></label>
          <input type="password" autocomplete="off" formControlName="password" class="form-control mb-2" placeholder="Password" >
          <div *ngIf=" (rf.password.touched) && (rf.password.invalid || rf.password.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Password is
            required</div>
        </div>
        <div class="form-group" *ngIf="!isLogin">
          <label>Confirm Password <span class="red">*</span></label>
          <input type="password" autocomplete="off" formControlName="confirm_password" class="form-control mb-2" placeholder="confirm Password"
            >
          <div *ngIf=" (rf.confirm_password.touched) && (rf.confirm_password.invalid || rf.confirm_password.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Confirm Password is
            required
          </div>
        </div>

        <div class="form-group" *ngIf="isLogin">
          <label>Password</label>
          <input type="password" formControlName="password" class="form-control mb-2" placeholder="Password"  autocomplete="off">
        </div>
        <div class="form-group" *ngIf="isLogin">
          <label>Confirm Password</label>
          <input type="password" formControlName="confirm_password" class="form-control mb-2" placeholder="confirm Password" autocomplete="off"
            >
        </div>
        <div class="form-group">
          <label>Profile Photo </label>
          <input type="file" formControlName="profile_photo" class="form-control" 
             (change)="fileEvent($event)">
             <br>
             <img [src]="identityurl" class="pnt" height="100" width="100">
        </div>


        <div class="d-flex  flex-sm-row flex-column">
          <button class="btn bg-light-primary mb-2 mb-sm-0 back-btn" (click)="goToLoginPage()">Back To Login</button>
          <button *ngIf="!isLogin" type="submit" class="btn btn-primary" (click)="registerUserData()">Register</button>
          <button  *ngIf="isLogin" type="submit" class="btn btn-primary" (click)="registerUserUpdateData()">Update</button>
          
        </div>
      </div>
      <div class="col-lg-6 ">

        <div class="form-group">
          <label>Address <span class="red">*</span></label>
          <textarea formControlName="address" class="form-control mb-2" placeholder="Address">
          </textarea>
          <div *ngIf=" (rf.address.touched) && (rf.address.invalid || rf.address.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Address is
            required</div>
        </div>
        
        <div class="form-group">
          <label>Country <span class="red">*</span></label>
          <select formControlName="country_id" id="country_id" class="form-control"
            [ngClass]="{ 'is-invalid': rf.country_id.touched && rf.country_id.errors }"  [(ngModel)]="selectedCountry"
            (change)='onChangeCountry($event.target.value)'  
          >
            <option value="0" selected>select your country</option>
            <option *ngFor="let item of countryList" value="{{item.id}}" >
              {{item.country_name}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>State <span class="red">*</span></label>
          <select formControlName="state_id" id="state_id"  class="form-control"
            [ngClass]="{ 'is-invalid': rf.state_id.touched && rf.state_id.errors }"  [(ngModel)]="selectedState"
            (change)='onChangeState($event.target.value)'  
          >
            <option value="0" selected>select your state</option>
            <option *ngFor="let item of stateList" selected="selectedState" value="{{item.id}}">
              {{item.state_name}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>City <span class="red">*</span></label>
          <select formControlName="city_id" id="city_id" class="form-control"
            [ngClass]="{ 'is-invalid': rf.city_id.touched && rf.city_id.errors }" [(ngModel)]="selectedCity">
            <option value="0" selected>select your city</option>
            <option *ngFor="let item of cityList" selected="selectedCity" value="{{item.id}}">
              {{item.city_name}}
            </option>
          </select>
        </div>
        
        <div class="form-group">
          <label>Pincode <span class="red">*</span></label>
          <input type="text" formControlName="pincode" class="form-control" placeholder="Pincode" required>
          <div *ngIf=" (rf.pincode.touched) && (rf.pincode.invalid || rf.pincode.errors?.required)"
            class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Pincode is
            required</div>
        </div>
        <div class="form-group">
          <label>Mobile <span class="red">*</span></label>
          <input type="text" formControlName="mobile" class="form-control" placeholder="Mobile" required>

          <div *ngIf=" (rf.mobile.touched) && (rf.mobile.invalid || rf.mobile.errors?.required)"
          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Mobile is
          required</div>
        </div>

        

      </div>
    </form>

  </div>
</div>
