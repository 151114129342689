import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-tax',
  templateUrl: './add-new-tax.component.html',
  styleUrls: ['./add-new-tax.component.scss']
})
export class AddNewTaxComponent implements OnInit {
  TaxFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  
  isUpdate:boolean = false;
  taxId  = window.sessionStorage.getItem("tax_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.isUpdateRecord();
    this.taxId = window.sessionStorage.getItem("tax_id");
    this.formId = {id:this.taxId};
    this.sharedService.getTaxDetail(this.formId).subscribe(res=>{
      this.formValues = {
        tax_name : res['data']['tax_name'],
        percent : res['data']['percent'],

      };
      this.selectedStatus = res['data']['status'];
      
      this.TaxFormGroup.patchValue(this.formValues);

    });


    this.TaxFormGroup = this.fb.group({
      tax_id:[this.taxId, Validators.required],
      tax_name:['', Validators.required],
      percent:['', Validators.required],
      status:['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.taxId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.TaxFormGroup.controls; }

  
  addTaxData(){
    this.sharedService.addTax(this.TaxFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateTaxData()
   {
     this.sharedService.editTax(this.TaxFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("tax_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
