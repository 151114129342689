import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDeshboardComponent } from '../login-deshboard/login-deshboard.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SignupComponent } from './signup/signup.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    LoginDeshboardComponent,
    RecoverPasswordComponent,
    SetPasswordComponent,
    SignupComponent,
    EmailVerificationComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  exports:[
    // ReactiveFormsModule,
    // FormsModule,
  ]
})
export class AuthenticationModule { }
