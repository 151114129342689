import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-state',
  templateUrl: './add-new-state.component.html',
  styleUrls: ['./add-new-state.component.scss']
})
export class AddNewStateComponent implements OnInit {
  StateFormGroup: FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  public selectedCountry: any;
  public countryList: any;

  isUpdate:boolean = false;
  stateId  = window.sessionStorage.getItem("state_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private sharedService: UserServiceService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCountryList();
    this.isUpdateRecord();
    this.formId = {id:this.stateId};
    this.sharedService.getStateDetail(this.formId).subscribe(res=>{
      this.formValues = {
        state_name : res['data']['state_name'],
      };
      this.selectedCountry = res['data']['country_id'];
      this.selectedStatus = res['data']['status'];
      
      console.log(this.formValues);
      
      this.StateFormGroup.patchValue(this.formValues);

    });


    this.StateFormGroup = this.fb.group({
      state_id:[this.stateId, Validators.required],
      country_id : ['', Validators.required],
      state_name: ['', Validators.required],
      status: ['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.stateId){
      this.isUpdate = true;
    }
  }

  getCountryList()
  {
    this.sharedService.getCountryList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
          this.countryList = resData['data'];
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.StateFormGroup.controls; }

  addStateData(){
    this.sharedService.addState(this.StateFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           window.location.reload();
           this.dialog.closeAll();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateStateData()
   {
     this.sharedService.editState(this.StateFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("state_id",null);
           this.TypeSuccess("Record updated successfully!");
           window.location.reload();
           this.dialog.closeAll();
           
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
