<div class="category-header">
    <div>Add New Product</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="ProductFormGroup">
        <input type="hidden" formControlName="product_id" />
        <div class="form-container">
            <div class="section1">
                <div class="form-group add-category">
                    <label>Category <span class="red">*</span></label>
                    <select formControlName="category_id" id="category_id" class="form-control"
                        [(ngModel)]="selectedCategory"
                        [ngClass]="{ 'is-invalid': f.category_id.touched && f.category_id.errors }">
                        <option value="0" selected>select your category</option>
                        <option *ngFor="let item of categoryList" value="{{item.id}}">
                            {{item.category_name}}
                        </option>
                    </select>
                    <div *ngIf="f.category_id.touched && f.category_id.errors" class="invalid-feedback">
                        <div *ngIf="f.category_id.errors.required">Category Name is required</div>
                    </div>
                </div>
                <div class="form-group add-category">
                    <label>Product Name <span class="red">*</span></label>
                    <input type="text" placeholder="Product Name" formControlName="product_name" class="form-control"
                        [ngClass]="{ 'is-invalid': f.product_name.touched && f.product_name.errors }" />
                    <div *ngIf="f.product_name.touched && f.product_name.errors" class="invalid-feedback">
                        <div *ngIf="f.product_name.errors.required">Product name is required</div>
                    </div>
                </div>
                <div class="form-group add-category">
                    <label>Product Photo </label>
                    <input type="file" formControlName="product_photo" class="form-control" 
                       (change)="fileEvent($event)">
                       <br>
                       <img [src]="identityurl" class="pnt" height="50" width="50">
                  </div>
                <div class="form-group add-category">
                    <label>Product Version </label>
                    <input type="text" placeholder="Product Version" formControlName="product_version"
                        class="form-control"/>
                    
                </div>
                <div class="form-group add-category">
                    <label>Original Price <span class="red">*</span></label>
                    <input type="text" placeholder="Original Price" formControlName="original_price"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': f.original_price.touched && f.original_price.errors }" />
                    <div *ngIf="f.original_price.touched && f.original_price.errors" class="invalid-feedback">
                        <div *ngIf="f.original_price.errors.required">Original price is required</div>
                    </div>
                </div>

               
            </div>
            <div class="section2">
                
                <div class="form-group add-category">
                    <label>Discount Rate </label>
                    <input type="text" placeholder="Discount Rate" formControlName="discount_rate" class="form-control"/>
                   
                </div>
                <div class="form-group add-category">
                     <label>Taxes </label>
                    <select formControlName="tax_ids" id="tax_ids" class="form-control" [(ngModel)]="selectedTaxIds" multiple>
                        <option value="0" selected>select tax</option>
                        <option *ngFor="let item of taxList" value="{{item.id}}">
                            {{item.tax_name}}
                        </option>
                    </select>
                </div>

                <div class="form-group add-category">
                    <label>Expiry Date </label>
                    <input type="date" formControlName="expiry_date" [(ngModel)]="expiryDate" class="form-control"/>
                    
                </div>

                <div class="form-group add-category">
                    <label>Warranty Date</label>
                    <input type="date" formControlName="warranty_date" [(ngModel)]="warrantyDate" class="form-control"/>
                   
                </div>
                
                <div class="form-group add-category">
                    <label>Status <span class="red">*</span></label>
                    <select formControlName="status" class="form-control" [(ngModel)]="selectedStatus"
                        [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }">
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                        <div *ngIf="f.status.errors.required">Status is required</div>
                    </div>
                </div>


            </div>

        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addProductData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateProductData()">EDIT</button>

        </div>
    </form>
</div>