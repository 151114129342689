import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-deshboard',
  templateUrl: './home-deshboard.component.html',
  styleUrls: ['./home-deshboard.component.scss']
})
export class HomeDeshboardComponent implements OnInit {
  
  constructor(private router: Router, public toastr : ToastrService, private sharedService : UserServiceService){}

  ngOnInit(): void {
   
  }
  goProfilePage(){
    this.router.navigate(['edit-profile'])
  }
  goHomePage()
  {
    sessionStorage.clear();
    this.router.navigate(['home-Deshboard'])
  }
  goSignUpPage()
  {
    this.router.navigate(['sign-up'])
  }
  
}
