<!--Forgot Password Starts-->
<section id="forgot-password">
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-12">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div
                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="../../../../assets/images/forgot.png" alt="" class="img-fluid" width="260" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <form class="form-group" [formGroup]="RecoverFormGroup">
                  <h4 class="mb-2 card-title">Recover Password</h4>
                  
                  <p class="card-text mb-3">Please enter your email address and we'll send you instructions on how to
                    reset your password.</p>
                  <input class="form-control mb-3" type="email" formControlName="email"  
                  [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }" />
                  <div *ngIf="f.email.touched && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                  </div>
                  <div class="d-flex flex-sm-row flex-column justify-content-between">
                    <button type="button" class="btn custom-bg-light-primary" (click)="openLoginPage()"> Back To
                      Login</button>
                    <button type="submit" class="btn btn-primary ml-sm-1" (click)="forgotPassword()">Recover</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->