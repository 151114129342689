<div class="category-header">
    <div>Add New Expense</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="ExpenseListsFormGroup">
        <input type="hidden" formControlName="expense_id" />
        
        <div class="form-group add-category">
            <label>Expense Title <span class="red">*</span></label>
            <input class="" type="text" formControlName="expense_name" class="form-control"
                [ngClass]="{ 'is-invalid': f.expense_name.touched && f.expense_name.errors }" />
            <div *ngIf="f.expense_name.touched && f.expense_name.errors" class="invalid-feedback">
                <div *ngIf="f.expense_name.errors.required">Expense Title is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addExpenseListsData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateExpenseListsData()">EDIT</button>

        </div>
    </form>
</div>