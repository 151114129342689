<!--Forgot Password Starts-->
<section id="forgot-password">
  <form class="form-group" [formGroup]="ResetFormGroup" >
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-12">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div
                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="../../../../assets/images/forgot.png" alt="" class="img-fluid" width="260" height="230">
              </div>
              
                <div class="col-lg-6 col-md-12 px-4 py-3" style="padding: 48px;">
                  <h1>Reset Password</h1>
                  <input class="password" type="password" formControlName="password" placeholder="Password"  autocomplete="off" 
                  [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" />
                  <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                  <input class="password" type="password" formControlName="confirm_password" placeholder="Confirm Password"  autocomplete="off" 
                  [ngClass]="{ 'is-invalid': f.confirm_password.touched && f.confirm_password.errors }" />
                  <div *ngIf="f.confirm_password.touched && f.confirm_password.errors" class="invalid-feedback">
                      <div *ngIf="f.confirm_password.errors.required">Confirm password is required</div>
                  </div>
                  <div class="verify-btn">
                    <!-- <a [routerLink]="['/pages/login']" class="btn bg-light-primary mb-2 mb-sm-0">Back To Login</a> -->
                    <button type="submit" class="btn btn-primary ml-sm-1" (click)="resetPassword()">Verify</button>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</section>
<!--Forgot Password Ends-->