<div class="category-header">
    <div>Add New Role</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="CategoryFormGroup">
        <div class="form-group add-category">
            <label>Role:</label>
            <input class="" type="text" formControlName="category" class="form-control" 
            [ngClass]="{ 'is-invalid': f.category.touched && f.category.errors }" />
            <div *ngIf="f.category.touched && f.category.errors" class="invalid-feedback">
                <div *ngIf="f.category.errors.required">Role is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status:</label>
            <select formControlName="categoryStatus" class="form-control" 
             [ngClass]="{ 'is-invalid': f.categoryStatus.touched && f.categoryStatus.errors }" value="option">
                <option>Active</option>
                <option>Inactive</option>
            </select>
            <div *ngIf="f.categoryStatus.touched && f.categoryStatus.errors" class="invalid-feedback">
                <div *ngIf="f.categoryStatus.errors.required">Status is required</div>
            </div>
        </div>
<div class="row form-group submit-btn">
    <button type="submit" class="btn btn-primary" >SUBMIT</button>
 
</div>
    </form>
</div>
