<!--table starts-->
<app-header></app-header>
<section id="ng-tables " class="main-section custom-control-row">
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <form>
                <div class="table-header-container">
                  <div class="form-group form-inline">
                    Full text search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
                    <span class="ml-3" *ngIf="service.loading$ | async">Loading...</span>
                  </div>
                
                  <button type="submit" class="btn btn-primary" (click)="addNewExpense()">ADD Expense</button>
                </div>
             
  
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <!-- <th scope="col">#</th> -->
                        <th scope="col" sortable="expense_name" (sort)="onSort($event)">Expense Title</th>
                        <th scope="col" sortable="expense_name" (sort)="onSort($event)">Cost</th>
                        <th scope="col" sortable="status" (sort)="onSort($event)">STATUS</th>
                        <th scope="col" sortable="expense_name" (sort)="onSort($event)">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let expense of countries$ | async">
                        <!-- <th scope="row">{{ expense.id }}</th> -->
                        
                        <td>
                          <ngb-highlight [result]="expense.expense_name" [term]="service.searchTerm"></ngb-highlight>
                        </td>
                        <td>
                          <ngb-highlight [result]="expense.cost" [term]="service.searchTerm"></ngb-highlight>
                        </td>
                        <td>
                          <ngb-highlight [result]="expense.status" [term]="service.searchTerm"></ngb-highlight>
                        </td>
                        <td class="action-btn">
                           <button type="button" class="glyphicon glyphicon-edit" (click)="updateExpenseData(expense.id)"> </button>
                          <button type="button" class="glyphicon glyphicon-trash" (click)="deleteExpense(expense.id)"></button>
                        </td>
                       
                      </tr>
                    </tbody>
                  </table>
                </div>
  
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                  </ngb-pagination>
  
                  <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                    <option [ngValue]="2">2 items per page</option>
                    <option [ngValue]="4">4 items per page</option>
                    <option [ngValue]="6">6 items per page</option>
                  </select>
                </div>
  
              </form>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  