import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SideNavBarComponent } from '../side-nav-bar/side-nav-bar.component';
import { HeaderComponent } from '../header/header.component';
import {MatMenuModule} from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
 //   OverlayModule, 
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    MatMenuModule,
    MatDialogModule,
    // SideNavBarComponent
  ],
  exports : [
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    MatMenuModule,
    MatDialogModule,
  ]
})
export class SharedModule { }
