import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLogin:boolean = false;
  title = 'accountAndInventory';
  userName = window.sessionStorage.getItem("username");

  ngOnInit(): void {
    this.isValidLogin();
  };

  isValidLogin(){
    if(this.userName != null || this.userName != undefined){
      this.isLogin = true;
    }
  }

}
