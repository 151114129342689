import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.scss']
})
export class SideNavBarComponent implements OnInit {
routerLinkList = [
  {name:'Home', iconURL:'Icon', link:'home-dashboard',categoryIcon:'../../assets/icons/category.png'},
  {name:'Categories', iconURL:'Icon', link:'category-dashboard',categoryIcon:'../../assets/icons/category.png'},
  {name:'Products', iconURL:'Icon', link:'products',categoryIcon:'../../assets/icons/category.png'},
  {name:'Taxes', iconURL:'Icon', link:'taxes',categoryIcon:'../../assets/icons/category.png'},
  {name:'Stocks', iconURL:'Icon', link:'stocks',categoryIcon:'../../assets/icons/category.png'},
  {name:'Parties', iconURL:'Icon', link:'parties',categoryIcon:'../../assets/icons/category.png'},
  {name:'Purchase Order', iconURL:'Icon', link:'purchase-orders',categoryIcon:'../../assets/icons/category.png'},
  {name:'Sales Order', iconURL:'Icon', link:'sales-orders',categoryIcon:'../../assets/icons/category.png'},
  
  {name:'Expense Master', iconURL:'Icon', link:'expense-lists',categoryIcon:'../../assets/icons/category.png'},
  {name:'Expenses', iconURL:'Icon', link:'expenses',categoryIcon:'../../assets/icons/category.png'},
  // {name:'Roles', iconURL:'Icon', link:'roles',categoryIcon:'../../assets/icons/roles.jpg'},
  // {name:'Permissions', iconURL:'Icon', link:'permissions',categoryIcon:'../../assets/icons/permission.png'},
  {name:'States', iconURL:'Icon', link:'states',categoryIcon:'../../assets/icons/state.png'},
  {name:'Countries', iconURL:'Icon', link:'countries',categoryIcon:'../../assets/icons/contury.png'},
  {name:'Cities', iconURL:'Icon', link:'cities',categoryIcon:'../../assets/icons/cities.png'},

]
  constructor() { }

  ngOnInit(): void {
  }

}
