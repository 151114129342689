import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-expense-lists',
  templateUrl: './add-new-expense-lists.component.html',
  styleUrls: ['./add-new-expense-lists.component.scss']
})
export class AddNewExpenseListsComponent implements OnInit {
  ExpenseListsFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  
  isUpdate:boolean = false;
  expenseId  = window.sessionStorage.getItem("expense_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.isUpdateRecord();
    this.expenseId = window.sessionStorage.getItem("expense_id");
    this.formId = {id:this.expenseId};
    this.sharedService.getExpenseListsDetail(this.formId).subscribe(res=>{
      this.formValues = {
        expense_name : res['data']['expense_name'],
        mobile : res['data']['mobile'],

      };
     
      this.selectedStatus = res['data']['status'];
      
      this.ExpenseListsFormGroup.patchValue(this.formValues);

    });


    this.ExpenseListsFormGroup = this.fb.group({
    expense_id:[this.expenseId, Validators.required],
    expense_name:['', Validators.required],
    status:['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.expenseId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.ExpenseListsFormGroup.controls; }

  
  addExpenseListsData(){
    this.sharedService.addExpenseLists(this.ExpenseListsFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateExpenseListsData()
   {
     this.sharedService.editExpenseLists(this.ExpenseListsFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("expense_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
