import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-party',
  templateUrl: './add-new-party.component.html',
  styleUrls: ['./add-new-party.component.scss']
})
export class AddNewPartyComponent implements OnInit {
  PartyFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  
  isUpdate:boolean = false;
  partyId  = window.sessionStorage.getItem("party_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.isUpdateRecord();
    this.partyId = window.sessionStorage.getItem("party_id");
    this.formId = {id:this.partyId};
    this.sharedService.getPartyDetail(this.formId).subscribe(res=>{
      this.formValues = {
        full_name : res['data']['full_name'],
        address : res['data']['address'],
        pincode : res['data']['pincode'],
        mobile : res['data']['mobile'],
        email : res['data']['email']
      };
     
      this.selectedStatus = res['data']['status'];
      
      this.PartyFormGroup.patchValue(this.formValues);

    });


    this.PartyFormGroup = this.fb.group({
    party_id:[this.partyId, Validators.required],
      full_name:['', Validators.required],
      address:['', Validators.required],
      pincode:['', Validators.required],
      mobile:['', Validators.required],
      email:['', Validators.required],
      status:['', Validators.required],
    })
  }

  isUpdateRecord()
  {
    if(this.partyId){
      this.isUpdate = true;
    }
  }

  
  // convenience getter for easy access to form fields
  get f() { return this.PartyFormGroup.controls; }

  
  addPartyData(){
    this.sharedService.addParty(this.PartyFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updatePartyData()
   {
     this.sharedService.editParty(this.PartyFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("party_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
