import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-permissions',
  templateUrl: './add-permissions.component.html',
  styleUrls: ['./add-permissions.component.scss']
})
export class AddPermissionsComponent implements OnInit {
  CategoryFormGroup : FormGroup
  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
    this.CategoryFormGroup = this.fb.group({
    category:['', Validators.required],
    categoryStatus:['', Validators.required]
    })
  }
  // convenience getter for easy access to form fields
  get f() { return this.CategoryFormGroup.controls; }
}
