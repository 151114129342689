<div class="category-header">
    <div>Add Stock</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="StockFormGroup">
        <input type="hidden" formControlName="stock_id" />
        <div class="form-group add-category">
            <label>Product <span class="red">*</span></label>
            <select formControlName="product_id" id="product_id" class="form-control" [(ngModel)]="selectedProduct"
                [ngClass]="{ 'is-invalid': f.product_id.touched && f.product_id.errors }">
                <option value="0" selected>select your product</option>
                <option *ngFor="let item of productList" value="{{item.id}}">
                    {{item.product_name}}
                </option>
            </select>
            <div *ngIf="f.product_id.touched && f.product_id.errors" class="invalid-feedback">
                <div *ngIf="f.product_id.errors.required">Product Name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Sales Stock</label>
            <input class="" type="text" formControlName="sales_stocks" class="form-control" />

        </div>
        <div class="form-group add-category">
            <label>Purchase Stock</label>
            <input class="" type="text" formControlName="purchase_stocks" class="form-control" />

        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addStockData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateStockData()">EDIT</button>

        </div>
    </form>
</div>