<div class="side-nav-container">
    <div class="side-nav-header">

        <div class="company-logo">
            <h1 class="card-title mb-2">globalitrack.com </h1>
            <p class="company-title">GCEC Company</p>
           </div>
    </div>
    <div class="side-nav-content" *ngFor="let NavList of routerLinkList">
        <div class="side-nav">
           <div class="icons"> <img [src]="NavList.categoryIcon"></div>
           <div class="menu-list"> <a [routerLink]="NavList.link">{{NavList.name}}</a></div>
        </div>
        <div>{{NavList.icon}}</div>
    </div>
</div>