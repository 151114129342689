<div class="category-header">
    <div>Add New Expense</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="ExpenseFormGroup">
        <input type="hidden" formControlName="expenses_id" />
       
        <div class="form-group add-category">
            <label>Expense <span class="red">*</span></label>
            <select formControlName="expense_id" id="expense_id" class="form-control"
                [(ngModel)]="selectedExpense"
                [ngClass]="{ 'is-invalid': f.expense_id.touched && f.expense_id.errors }">
                <option value="0" selected>please select expense</option>
                <option *ngFor="let item of expenseList" value="{{item.id}}">
                    {{item.expense_name}}
                </option>
            </select>
            <div *ngIf="f.expense_id.touched && f.expense_id.errors" class="invalid-feedback">
                <div *ngIf="f.expense_id.errors.required">Expense title is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Cost <span class="red">*</span></label>
            <input class="" type="text" formControlName="cost" class="form-control"
                [ngClass]="{ 'is-invalid': f.cost.touched && f.cost.errors }" />
            <div *ngIf="f.cost.touched && f.cost.errors" class="invalid-feedback">
                <div *ngIf="f.cost.errors.required">Cost is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addExpenseData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateExpenseData()">EDIT</button>

        </div>
    </form>
</div>