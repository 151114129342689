import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import {DecimalPipe} from '@angular/common';

import {CountryService} from './country.service';
import {Country} from './country';

import {NgbdSortableHeader, SortEvent} from 'src/app/product/sortable.directive';
import { MatDialog } from '@angular/material/dialog';
import { ThisReceiver } from '@angular/compiler';
import { AddProductComponent } from './add-product/add-product.component';
import { UserServiceService } from 'src/app/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [CountryService, DecimalPipe]
})
export class ProductComponent implements OnInit {
  ProductFormGroup : FormGroup;
  countries$: any;
  total$: Observable<number>;
  formId : any;
  public selectedStatus: any;


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private fb: FormBuilder,public service: CountryService, public dialog:MatDialog, private toastr: ToastrService, private sharedService : UserServiceService) {
    this.countries$ = service.countries$;
    this.total$ = service.total$;
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit(): void {
  }

  get f() {
    return this.ProductFormGroup.controls;
  }

  addNewProduct(){
    window.sessionStorage.setItem("product_id","");
    this.dialog.open(AddProductComponent,{
      disableClose:true
    })
  }

  updateProductData(id = null){
    window.sessionStorage.setItem("product_id",id);
    this.dialog.open(AddProductComponent,{
      disableClose:true
    })
  }

  deleteProduct(id = null){
   
    this.formId = {id : id};
    // @ts-ignore
    Swal.fire({
      title: 'Are you sure you want to delete this product?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.sharedService.deleteProduct(this.formId).subscribe(res => {
            if(res['meta']['code'] == 1)
            {
                this.TypeSuccess("Record deleted successfully!");
                window.location.reload();
            }
            else
            {
              this.TypeError(res['meta']['message']);
            }
          }, err => {
              this.toastr.error(err, 'Error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  
  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }
  
  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
