import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  registerFormSubmitted = false;
  registerForm: FormGroup;
  result = "";
  color = '';
  isLogin: boolean = false;
  userName = window.sessionStorage.getItem("username");

  public formValues: any = {};
  public formId: any;
  public selectedCountry: any;
  public selectedState: any;
  public selectedCity: any;
  public userData: any;
  public countryList: any;
  public stateList: any;
  public cityList: any;

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private sharedService: UserServiceService, private cd: ChangeDetectorRef) {
    this.getCountryList();
    this.getStateList();
    this.getCityList();
    this.isValidLogin();
  }

  isValidLogin() {
    if (this.userName != null || this.userName != undefined) {
      this.isLogin = true;
    }
  }

  getCountryList() {
    this.sharedService.getCountryList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.countryList = resData['data'];
      }
    });
  }

  getStateList() {
    this.sharedService.getStateList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.stateList = resData['data'];
      }
    });
  }

  getCityList() {
    this.sharedService.getCityList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.cityList = resData['data'];
      }
    });
  }

  onChangeCountry(countryId: number) {
    this.formId = { id: countryId };
    this.sharedService.getStateByCountry(this.formId).subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.stateList = resData['data'];
      }
    });
  }

  onChangeState(stateId: number) {
    this.formId = { id: stateId };
    this.sharedService.getCityByState(this.formId).subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.cityList = resData['data'];
      }
    });
  }


  filedata: any;
  identityurl: any = environment.frontUrl + 'assets/images/avatar.jpg';
  identitypdfurl: any = '';
  public identityfileObj: any;
  public fileExtension: any;
  fileExtensionMessage = false;


  fileEvent(event) {
    const reader = new FileReader();
    this.fileExtensionMessage = false;
    if (!this.validateFile(event.target.files[0].name)) {
      this.fileExtensionMessage = true;
      return false;
    }
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.identityfileObj = file;
      reader.onload = (event: ProgressEvent) => {
        if (this.fileExtension === 'pdf') {
          this.identityurl = environment.frontUrl + 'assets/icons/category.png';
        } else {
          this.identityurl = (<FileReader>event.target).result;
        }
        this.registerForm.patchValue({
          identity_image: reader.result
        });
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'jpeg' || ext.toLowerCase() === 'pdf') {
      this.fileExtensionMessage = false;
      this.fileExtension = ext.toLowerCase();
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    
    this.getUserProfileData();

    this.registerForm = this.fb.group({
      user_id: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required],
      username: ['', Validators.required],
      address: ['', Validators.required],
      city_id: ['', Validators.required],
      state_id: ['', Validators.required],
      country_id: ['', Validators.required, Validators.min(1)],
      pincode: ['', Validators.required],
      profile_photo: ['', Validators.required],

      mobile: ['', Validators.required]

    }

    );
  }
  get rf() {
    return this.registerForm.controls;
  }

  //  On submit click, reset field value
  onSubmit() {
    this.registerFormSubmitted = true;
    if (this.registerForm.invalid) {

      return;

    }
    else {
    }
  }

  goToLoginPage() {
    this.router.navigate(['account-login'])
  }

  registerUserUpdateData() {
    // userData['profile_photo'] =  this.filedata;
    var userId = window.sessionStorage.getItem("user_id");

    const formData = new FormData();
    formData.append('profile_photo', this.identityfileObj);
    formData.append('user_id', this.registerForm.value.user_id);
    formData.append('first_name', this.registerForm.value.first_name);
    formData.append('last_name', this.registerForm.value.last_name);
    formData.append('email', this.registerForm.value.email);
    formData.append('password', this.registerForm.value.password);
    formData.append('confirm_password', this.registerForm.value.confirm_password);
    formData.append('username', this.registerForm.value.username);
    formData.append('address', this.registerForm.value.address);
    formData.append('city_id', this.registerForm.value.city_id);
    formData.append('state_id', this.registerForm.value.state_id);
    formData.append('country_id', this.registerForm.value.country_id);
    formData.append('pincode', this.registerForm.value.pincode);
    formData.append('mobile', this.registerForm.value.mobile);

    this.sharedService.editUserProfile(formData).subscribe(res => {
      if (res['meta']['code'] == 1) {
        this.TypeSuccess("Record updated successfully!");
        // this.router.navigate(['home-dashboard'])
      }
      else {
        this.TypeError(res['meta']['message']);
      }
    })

  }

  registerUserData() {

    const formData = new FormData();
    formData.append('profile_photo', this.identityfileObj);
    formData.append('user_id', this.registerForm.value.user_id);
    formData.append('first_name', this.registerForm.value.first_name);
    formData.append('last_name', this.registerForm.value.last_name);
    formData.append('email', this.registerForm.value.email);
    formData.append('password', this.registerForm.value.password);
    formData.append('confirm_password', this.registerForm.value.confirm_password);
    formData.append('username', this.registerForm.value.username);
    formData.append('address', this.registerForm.value.address);
    formData.append('city_id', this.registerForm.value.city_id);
    formData.append('state_id', this.registerForm.value.state_id);
    formData.append('country_id', this.registerForm.value.country_id);
    formData.append('pincode', this.registerForm.value.pincode);
    formData.append('mobile', this.registerForm.value.mobile);

    this.sharedService.registerUser(formData).subscribe(res => {
      if (res['meta']['code'] == 1) {
        this.TypeSuccess("User registered successfully!");
        window.location.href = environment.frontUrl;
      }
      else {
        this.TypeError(res['meta']['message']);
      }
    })

  }

  getUserProfileData() {
    var userId = window.sessionStorage.getItem("user_id");
    var userProfileId = { id: userId };
    this.sharedService.getUserProfile(userProfileId).subscribe(res => {

      this.formValues = {
        user_id: res['data']['user_id'],
        first_name: res['data']['first_name'],
        last_name: res['data']['last_name'],
        email: res['data']['email'],
        username: res['data']['username'],
        password: '',
        confirm_password: '',
        address: res['data']['address'],
        pincode: res['data']['pincode'],
        mobile: res['data']['mobile'],
        profile_photo: res['data']['profile_photo'],
        country_id: res['data']['country_id'],
        state_id: res['data']['state_id'],
        city_id: res['data']['city_id']
      };
      this.selectedCountry = res['data']['country_id'];
      this.selectedState = res['data']['state_id'];
      this.selectedCity = res['data']['city_id'];
      this.identityurl = res['data']['profile_photo'];
      this.registerForm.patchValue(this.formValues);

    })
  }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

}


