import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  ResetFormGroup: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private sharedService: UserServiceService) { }
  result = "";
  color = '';
  userData : any;

  ngOnInit(): void {
    this.ResetFormGroup = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    })
  }

  get f() { return this.ResetFormGroup.controls; }

  resetPassword() {
   this.userData = this.ResetFormGroup.value;
   this.userData.user_id = window.sessionStorage.getItem('user_id');
    console.log(this.userData);
    this.sharedService.resetPasswordData(this.userData).subscribe(res => {
      console.log(res);
      if (res['meta']['code'] == 1) {
        this.TypeSuccess("Your password reset successfully!");
        window.location.href = environment.frontUrl;
      }
      else {
        this.TypeError(res['meta']['message']); 
      }
    })

  }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }
  
  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

}
