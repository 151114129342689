import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-deshboard',
  templateUrl: './login-deshboard.component.html',
  styleUrls: ['./login-deshboard.component.scss']
})
export class LoginDeshboardComponent implements OnInit {
  LoginFormGroup: FormGroup;
  private StorageData: any = {};

  constructor(private fb: FormBuilder,private Router: Router, public toastr : ToastrService, private sharedService : UserServiceService){}
  result = "";
  color = '';
  ngOnInit(): void {
    this.LoginFormGroup = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }
  get f() { return this.LoginFormGroup.controls; }

  getLoginData(){
    this.sharedService.checkLogin(this.LoginFormGroup.value).subscribe(res=>{
        if(res['meta']['code'] == '1')
        {
            setTimeout(() => {
                this.StorageData = {
                    data: res['data'],
                    token: res['meta']['token'],
                };
                localStorage.setItem('loginData', JSON.stringify(this.StorageData));
                this.TypeSuccess('You Have logged in successfully');
                window.sessionStorage.setItem("username",res['data']['username']);
                window.sessionStorage.setItem("user_id",res['data']['id']);
                this.Router.navigate(['home-dashboard']);
            }, 500);

            
        }
        else
        {
          this.TypeError(res['meta']['message']);
        }
    })
    
  }

  openForgotPassword(){
    this.Router.navigate(["/forgot-password"])
  }
  createAccount(){
    this.Router.navigate(['sign-up'])
  }
  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // TypeWarning() {
  //   this.toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!');
  // }

  // // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

  // // Timeout
  // Timeout() {
  //   this.toastr.success('I do not think that word means what you think it means.', 'Timeout!', {
  //     "timeOut": 5000
  //   });
  // }

  // // Sticky
  // Sticky() {
  //   this.toastr.info('I do not think that word means what you think it means.', 'Sticky!', {
  //     "timeOut": 0
  //   });
  // }
}
