<!--Registration Page Starts-->
<section id="regestration" class="auth-height">
    <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0"> 
                            <div
                                class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                <img src="../../assets/images/register.png" alt="" class="img-fluid"
                                    width="350" height="230">
                            </div>
                            <div class="col-lg-6 col-md-12 px-4 py-3">
                              
                                <input type="email" class="form-control mb-2" placeholder="Email">
                                <!-- <input type="password" class="form-control mb-2" placeholder="Password">
                                <input type="password" class="form-control mb-2" placeholder="Confirm Password"> -->
                                <div class="text-right">
                                    <button type="button" class="btn btn-primary">Send OTP</button>
                                </div>
                                <div class="otp-container">
                                    <input maxlength="1"  type="text">
                                    <input maxlength="1" type="text">
                                    <input maxlength="1" type="text">
                                    <input maxlength="1" type="text">
                                    <input maxlength="1" type="text">
                                    <input maxlength="1" type="text">
                                </div>
                                <div class="text-right">
                                    <button type="button" class="btn btn-primary">Verify</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Registration Page Ends-->
