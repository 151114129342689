import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  CategoryFormGroup : FormGroup
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  isUpdate:boolean = false;
  categoryId  = window.sessionStorage.getItem("category_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.isUpdateRecord();
    this.categoryId = window.sessionStorage.getItem("category_id");
    this.formId = {id:this.categoryId};

    this.sharedService.getCategoryDetail(this.formId).subscribe(res=>{
      this.formValues = {
        category_name : res['data']['category_name'],
      };
      this.selectedStatus = res['data']['status'];
      
      this.CategoryFormGroup.patchValue(this.formValues);

    });

    this.CategoryFormGroup = this.fb.group({
    category_id:[this.categoryId, Validators.required],
    category_name:['', Validators.required],
    status:['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.categoryId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.CategoryFormGroup.controls; }

  addCategoryData(){
    this.sharedService.addCategory(this.CategoryFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateCategoryData()
   {
     this.sharedService.editCategory(this.CategoryFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("category_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
