<!--table starts-->
<app-header></app-header>
<section id="ng-tables " class="main-section custom-control-row">
  <div class="row text-left">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <form>
              <div class="table-header-container">
                <div class="form-group form-inline">
                  Full text search: <input class="form-control ml-2" type="text" name="searchTerm"
                    [(ngModel)]="service.searchTerm" />
                  <span class="ml-3" *ngIf="service.loading$ | async">Loading...</span>
                </div>

                <button type="submit" class="btn btn-primary" (click)="addNewSalesOrder()">ADD SALES
                  ORDER</button>
              </div>


              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <!-- <th scope="col">#</th> -->

                      <th scope="col" sortable="product_name" (sort)="onSort($event)">PRODUCT</th>
                      <th scope="col" sortable="product_name" (sort)="onSort($event)">AVAILABLE STOCKS</th>
                      <th scope="col" sortable="product_name" (sort)="onSort($event)">SALES STOCKS</th>
                      <th scope="col" sortable="product_name" (sort)="onSort($event)">PRODUCT PRICE</th>

                      <th scope="col" sortable="product_name" (sort)="onSort($event)">TOTAL PRICE</th>
                      <th scope="col" sortable="party_name" (sort)="onSort($event)">PARTY NAME</th>
                      <th scope="col" sortable="product_name" (sort)="onSort($event)">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let salesOrder of countries$ | async">
                      <!-- <th scope="row">{{ salesOrder.id }}</th> -->

                      <td>
                        <ngb-highlight [result]="salesOrder.product_name" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="salesOrder.available_stocks" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="salesOrder.sales_stocks" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="salesOrder.final_price" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="salesOrder.total_price" [term]="service.searchTerm"></ngb-highlight>
                      </td>
                      <td>
                        <ngb-highlight [result]="salesOrder.full_name" [term]="service.searchTerm"></ngb-highlight>
                      </td>

                      
                      <td class="action-btn">
                        <button type="button" class="glyphicon glyphicon-edit"
                          (click)="updateSalesOrderData(salesOrder.id)"> </button>
                        <button type="button" class="glyphicon glyphicon-trash"
                          (click)="deleteSalesOrder(salesOrder.id)"></button>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>

                <select class="custom-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                  <option [ngValue]="2">2 items per page</option>
                  <option [ngValue]="4">4 items per page</option>
                  <option [ngValue]="6">6 items per page</option>
                </select>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>