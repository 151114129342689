<div class="category-header">
    <div>Add New Party</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="PartyFormGroup">
        <input type="hidden" formControlName="party_id" />
        <div class="form-container">
            <div class="section1">
                
                <div class="form-group add-category">
                    <label>Full Name <span class="red">*</span></label>
                    <input type="text" placeholder="Full Name" formControlName="full_name" class="form-control"
                        [ngClass]="{ 'is-invalid': f.full_name.touched && f.full_name.errors }" />
                    <div *ngIf="f.full_name.touched && f.full_name.errors" class="invalid-feedback">
                        <div *ngIf="f.full_name.errors.required">First name is required</div>
                    </div>
                </div>
               
                <div class="form-group add-category">
                    <label>Address <span class="red">*</span></label>
                    <textarea formControlName="address" class="form-control mb-2" placeholder="Address">
                    </textarea>
                    <div *ngIf=" (f.address.touched) && (f.address.invalid || f.address.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Address is
                      required</div>
                </div>

                <div class="form-group add-category">
                    <label>Pincode <span class="red">*</span></label>
                    <input type="text" formControlName="pincode" class="form-control" placeholder="Pincode" required>
                    <div *ngIf=" (f.pincode.touched) && (f.pincode.invalid || f.pincode.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Pincode is
                      required</div>
                  </div>

            </div>
            <div class="section2">
                
                  <div class="form-group add-category">
                    <label>Mobile <span class="red">*</span></label>
                    <input type="text" formControlName="mobile" class="form-control" placeholder="Mobile" required>
          
                    <div *ngIf=" (f.mobile.touched) && (f.mobile.invalid || f.mobile.errors?.required)"
                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Mobile is
                    required</div>
                  </div>

                  <div class="form-group add-category">
                    <label>Email <span class="red">*</span></label>
                    <input type="text" autocomplete="off" formControlName="email" class="form-control mb-2" placeholder="Email" required>
                    <div *ngIf=" (f.email.touched) && (f.email.invalid || f.email.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Email is
                      required</div>
          
                  </div>
                <div class="form-group add-category">
                    <label>Status <span class="red">*</span></label>
                    <select formControlName="status" class="form-control" [(ngModel)]="selectedStatus"
                        [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }">
                        <option value="1" selected>Active</option>
                        <option value="0">Inactive</option>
                    </select>
                    <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                        <div *ngIf="f.status.errors.required">Status is required</div>
                    </div>
                </div>


            </div>

        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addPartyData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updatePartyData()">EDIT</button>

        </div>
    </form>
</div>