<div class="category-header">
    <div>Add New Country</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="CountryFormGroup">
        <input type="hidden" formControlName="country_id" />
        <div class="form-group add-category">
            <label>Country Name <span class="red">*</span></label>
            <input class="" type="text" formControlName="country_name" class="form-control"
                [ngClass]="{ 'is-invalid': f.country_name.touched && f.country_name.errors }" />
            <div *ngIf="f.country_name.touched && f.country_name.errors" class="invalid-feedback">
                <div *ngIf="f.country_name.errors.required">Country Name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addCountryData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateCountryData()">EDIT</button>

        </div>
    </form>
</div>