import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-city',
  templateUrl: './add-new-city.component.html',
  styleUrls: ['./add-new-city.component.scss']
})
export class AddNewCityComponent implements OnInit {
  CityFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  public selectedState: any;
  public selectedCountry: any;
  public stateList: any;
  public countryList: any;

  isUpdate:boolean = false;
  cityId  = window.sessionStorage.getItem("city_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.getStateList();
    this.getCountryList();
    this.isUpdateRecord();
    this.cityId = window.sessionStorage.getItem("city_id");
    this.formId = {id:this.cityId};
    this.sharedService.getCityDetail(this.formId).subscribe(res=>{
      this.formValues = {
        city_name : res['data']['city_name'],
      };
      this.selectedState = res['data']['state_id'];
      this.selectedCountry = res['data']['country_id'];
      this.selectedStatus = res['data']['status'];
      
      this.CityFormGroup.patchValue(this.formValues);

    });


    this.CityFormGroup = this.fb.group({
    city_id:[this.cityId, Validators.required],
    state_id : ['', Validators.required],
    country_id : ['', Validators.required],
    city_name:['', Validators.required],
    status:['', Validators.required]
    })
  }

  isUpdateRecord()
  {
    if(this.cityId){
      this.isUpdate = true;
    }
  }

  getStateList()
  {
    this.sharedService.getStateList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
          this.stateList = resData['data'];
      }
    });
  }

  getCountryList()
  {
    this.sharedService.getCountryList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
          this.countryList = resData['data'];
      }
    });
  }
  
  onChangeCountry(countryId: number) {
    this.formId = { id: countryId };
    this.sharedService.getStateByCountry(this.formId).subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.stateList = resData['data'];
      }
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.CityFormGroup.controls; }

  
  addCityData(){
    this.sharedService.addCity(this.CityFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateCityData()
   {
     this.sharedService.editCity(this.CityFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("city_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
