<div class="category-header">
    <div>Add New State</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="StateFormGroup">
        <input type="hidden" formControlName="country_id" />
        <div class="form-group add-category">
            <label>Country <span class="red">*</span></label>
            <select formControlName="country_id" id="country_id" class="form-control"
                [(ngModel)]="selectedCountry"
                [ngClass]="{ 'is-invalid': f.country_id.touched && f.country_id.errors }">
                <option value="0" selected>please select country</option>
                <option *ngFor="let item of countryList" value="{{item.id}}">
                    {{item.country_name}}
                </option>
            </select>
            <div *ngIf="f.country_id.touched && f.country_id.errors" class="invalid-feedback">
                <div *ngIf="f.country_id.errors.required">Country Name is required</div>
            </div>
        </div>

        <div class="form-group add-category">
            <label>State Name <span class="red">*</span></label>
            <input class="" type="text" formControlName="state_name" class="form-control"
                [ngClass]="{ 'is-invalid': f.state_name.touched && f.state_name.errors }" />
            <div *ngIf="f.state_name.touched && f.state_name.errors" class="invalid-feedback">
                <div *ngIf="f.state_name.errors.required">State name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1" selected>Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addStateData()">SUBMIT</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateStateData()">EDIT</button>
        </div>
    </form>
</div>