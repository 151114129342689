<div class="category-header">
    <div>Add New Tax</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="TaxFormGroup">
        <input type="hidden" formControlName="tax_id" />
   
        <div class="form-group add-category">
            <label>Tax Name <span class="red">*</span></label>
            <input class="" type="text" formControlName="tax_name" class="form-control"
                [ngClass]="{ 'is-invalid': f.tax_name.touched && f.tax_name.errors }" />
            <div *ngIf="f.tax_name.touched && f.tax_name.errors" class="invalid-feedback">
                <div *ngIf="f.tax_name.errors.required">Tax name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Percent <span class="red">*</span></label>
            <input class="" type="text" formControlName="percent" class="form-control"
                [ngClass]="{ 'is-invalid': f.percent.touched && f.percent.errors }" />
            <div *ngIf="f.percent.touched && f.percent.errors" class="invalid-feedback">
                <div *ngIf="f.percent.errors.required">Percent is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addTaxData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateTaxData()">EDIT</button>

        </div>
    </form>
</div>