import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import 'rxjs/Rx';
import 'rxjs-compat/add/operator/catch';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  baseUrl:string = environment.apiUrl;
  private httpOptions: any;
  private httpOptionsIfsc: any;
  private loginData: any;
  
  public static isAuthenticated(): boolean {
    const token = JSON.parse(localStorage.getItem('loginData'));
    if (token) {
        return true;
    }
    return false;
  }

  constructor(private http:HttpClient,
                private router: Router,
                private toastr: ToastrService ) { }
  
  getToken(slug = null) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.loginData.token,
            'module': slug
        }),
    };
    return this.httpOptions;
}
getToken3() {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.loginData.token,
        }),
    };
    return this.httpOptions;
}

getToken2(slug = null) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.httpOptions = new HttpHeaders({ 'Authorization': `Bearer ${this.loginData.token}` });
    this.httpOptions.append('Content-Type', 'multipart/form-data');
    this.httpOptions.append('Accept', 'application/json');
    this.httpOptions.append('slug', slug);
    return {headers: this.httpOptions};
}

getIfscToken() {
    this.httpOptionsIfsc = {
        headers: new HttpHeaders({
            'DY-X-Authorization': '3418ddf5cdda20d3ce4089b969769f00f5afb66'
        }),
    };
    return this.httpOptionsIfsc;
}

private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
        localStorage.removeItem('loginData');
        this.router.navigate(['login']);
    }
    return Observable.throw(err.error.message);
}

  checkLogin(body = null): Observable<any>{
    return this.http.post(this.baseUrl+'/login', body);
  }

  registerUser(body = null): Observable<any>{
    return this.http.post(this.baseUrl+'/sign-up', body);
  }
  editUserProfile(body = null): Observable<any> {
    return this.http.post(this.baseUrl+'/edit-user-profile', body, this.getToken('edit-user-profile')).catch(x => this.handleAuthError(x));
  }
  getUserProfile(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-user-profile',body, this.getToken('get-user-profile')).catch(x => this.handleAuthError(x));
  }
  recoverPassword(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/recover-password',body,this.getToken('recover-password')).catch(x => this.handleAuthError(x));
  }
  resetPasswordData(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/reset-password',body,this.getToken('reset-password')).catch(x => this.handleAuthError(x));
  }
  getCountryList(body = null):Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-country-list',body,this.getToken('get-country-list')).catch(x => this.handleAuthError(x));
  }
  
  getCountryAllList(body = null):Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-country-list',body,this.getToken('get-all-country-list')).catch(x => this.handleAuthError(x));
  }
  
  getStateList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-state-list',body,this.getToken('get-state-list')).catch(x => this.handleAuthError(x));
  }
  getCityList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-city-list',body,this.getToken('get-city-list')).catch(x => this.handleAuthError(x));
  }
  
  getStateByCountry(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-states-by-country',body,this.getToken('get-states-by-country')).catch(x => this.handleAuthError(x));
  }
  getCityByState(body = null)
  {
    return this.http.post(this.baseUrl+'/get-cities-by-state',body,this.getToken('get-cities-by-state')).catch(x => this.handleAuthError(x));
  }

  addCountry(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-country',body,this.getToken('add-country')).catch(x => this.handleAuthError(x));
  }
  
  editCountry(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-country',body,this.getToken('edit-country')).catch(x => this.handleAuthError(x));
  }
  deleteCountry(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-country',body,this.getToken('delete-country')).catch(x => this.handleAuthError(x));
  }
  getCountryDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-country-detail',body,this.getToken('get-country-detail')).catch(x => this.handleAuthError(x));
  }
  addState(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-state',body,this.getToken('add-state')).catch(x => this.handleAuthError(x));
  }
  editState(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-state',body,this.getToken('edit-state')).catch(x => this.handleAuthError(x));
  }
  getStateDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-state-detail',body,this.getToken('get-state-detail')).catch(x => this.handleAuthError(x));
  }
  getStateAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-state-list',body,this.getToken('get-all-state-list')).catch(x => this.handleAuthError(x));
  }
  deleteState(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-state',body,this.getToken('delete-state')).catch(x => this.handleAuthError(x));
  }
  
  addCity(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-city',body,this.getToken('add-city')).catch(x => this.handleAuthError(x));
  }
  editCity(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-city',body,this.getToken('edit-city')).catch(x => this.handleAuthError(x));
  }
  deleteCity(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-city',body,this.getToken('delete-city')).catch(x => this.handleAuthError(x));
  }
  getCityAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-city-list',body,this.getToken('get-all-city-list')).catch(x => this.handleAuthError(x));
  }
  getCityDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-city-detail',body,this.getToken('get-city-detail')).catch(x => this.handleAuthError(x));
  }
  
  getCategoryAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-category-list',body,this.getToken('get-all-category-list')).catch(x => this.handleAuthError(x));
  }
  addCategory(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-category',body,this.getToken('add-category')).catch(x => this.handleAuthError(x));
  }
  editCategory(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-category',body,this.getToken('edit-category')).catch(x => this.handleAuthError(x));
  }
  deleteCategory(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-category',body,this.getToken('delete-category')).catch(x => this.handleAuthError(x));
  }
  getCategoryDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-category-detail',body,this.getToken('get-category-detail')).catch(x => this.handleAuthError(x));
  }

  getProductAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-product-list',body,this.getToken('get-all-product-list')).catch(x => this.handleAuthError(x));
  }
  addProduct(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-product',body,this.getToken('add-product')).catch(x => this.handleAuthError(x));
  }
  editProduct(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-product',body,this.getToken('edit-product')).catch(x => this.handleAuthError(x));
  }
  deleteProduct(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-product',body,this.getToken('delete-product')).catch(x => this.handleAuthError(x));
  }
  getProductDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-product-detail',body,this.getToken('get-product-detail')).catch(x => this.handleAuthError(x));
  }
  getCategoryList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-category-list',body,this.getToken('get-category-list')).catch(x => this.handleAuthError(x));
  }
  getProductList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-product-list',body,this.getToken('get-product-list')).catch(x => this.handleAuthError(x));
  }
  getTaxList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-tax-list',body,this.getToken('get-tax-list')).catch(x => this.handleAuthError(x));
  }

  getStockAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-stock-list',body,this.getToken('get-all-stock-list')).catch(x => this.handleAuthError(x));
  }
  addStock(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-stock',body,this.getToken('add-stock')).catch(x => this.handleAuthError(x));
  }
  editStock(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-stock',body,this.getToken('edit-stock')).catch(x => this.handleAuthError(x));
  }
  deleteStock(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-stock',body,this.getToken('delete-stock')).catch(x => this.handleAuthError(x));
  }
  getStockDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-stock-detail',body,this.getToken('get-stock-detail')).catch(x => this.handleAuthError(x));
  }
  
  addParty(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-party',body,this.getToken('add-party')).catch(x => this.handleAuthError(x));
  }
  editParty(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-party',body,this.getToken('edit-party')).catch(x => this.handleAuthError(x));
  }
  deleteParty(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-party',body,this.getToken('delete-party')).catch(x => this.handleAuthError(x));
  }
  getPartyAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-party-list',body,this.getToken('get-all-party-list')).catch(x => this.handleAuthError(x));
  }
  getPartyDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-party-detail',body,this.getToken('get-party-detail')).catch(x => this.handleAuthError(x));
  }

  addTax(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-tax',body,this.getToken('add-tax')).catch(x => this.handleAuthError(x));
  }
  editTax(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-tax',body,this.getToken('edit-tax')).catch(x => this.handleAuthError(x));
  }
  deleteTax(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-tax',body,this.getToken('delete-tax')).catch(x => this.handleAuthError(x));
  }
  getTaxAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-tax-list',body,this.getToken('get-all-tax-list')).catch(x => this.handleAuthError(x));
  }
  getTaxDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-tax-detail',body,this.getToken('get-tax-detail')).catch(x => this.handleAuthError(x));
  }

  getPurchaseOrderAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-purchase-order-list',body,this.getToken('get-all-purchase-order-list')).catch(x => this.handleAuthError(x));
  }
  addPurchaseOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-purchase-order',body,this.getToken('add-purchase-order')).catch(x => this.handleAuthError(x));
  }
  editPurchaseOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-purchase-order',body,this.getToken('edit-purchase-order')).catch(x => this.handleAuthError(x));
  }
  deletePurchaseOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-purchase-order',body,this.getToken('delete-purchase-order')).catch(x => this.handleAuthError(x));
  }
  getPurchaseOrderDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-purchase-order-detail',body,this.getToken('get-purchase-order-detail')).catch(x => this.handleAuthError(x));
  }

  getSalesOrderAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-sales-order-list',body,this.getToken('get-all-sales-order-list')).catch(x => this.handleAuthError(x));
  }
  addSalesOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-sales-order',body,this.getToken('add-sales-order')).catch(x => this.handleAuthError(x));
  }
  editSalesOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-sales-order',body,this.getToken('edit-sales-order')).catch(x => this.handleAuthError(x));
  }
  deleteSalesOrder(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-sales-order',body,this.getToken('delete-sales-order')).catch(x => this.handleAuthError(x));
  }
  getSalesOrderDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-sales-order-detail',body,this.getToken('get-sales-order-detail')).catch(x => this.handleAuthError(x));
  }
  getSelectedTaxIds(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-selected-tax-ids',body,this.getToken('get-selected-tax-ids')).catch(x => this.handleAuthError(x));
  }
  getPartyList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-party-list',body,this.getToken('get-party-list')).catch(x => this.handleAuthError(x));
  }

  addExpense(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-expense',body,this.getToken('add-expense')).catch(x => this.handleAuthError(x));
  }
  editExpense(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-expense',body,this.getToken('edit-expense')).catch(x => this.handleAuthError(x));
  }
  deleteExpense(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-expense',body,this.getToken('delete-expense')).catch(x => this.handleAuthError(x));
  }
  getExpenseAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-expense-list',body,this.getToken('get-all-expense-list')).catch(x => this.handleAuthError(x));
  }
  getExpenseDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-expense-detail',body,this.getToken('get-expense-detail')).catch(x => this.handleAuthError(x));
  }
  getExpenseList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-expense-list',body,this.getToken('get-expense-list')).catch(x => this.handleAuthError(x));
  }

  //Expense Lists
  addExpenseLists(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/add-expense-lists',body,this.getToken('add-expense-lists')).catch(x => this.handleAuthError(x));
  }
  editExpenseLists(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/edit-expense-lists',body,this.getToken('edit-expense-lists')).catch(x => this.handleAuthError(x));
  }
  deleteExpenseLists(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/delete-expense-lists',body,this.getToken('delete-expense-lists')).catch(x => this.handleAuthError(x));
  }
  getExpenseListsAllList(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-all-expense-lists-list',body,this.getToken('get-all-expense-lists-list')).catch(x => this.handleAuthError(x));
  }
  getExpenseListsDetail(body = null): Observable<any>
  {
    return this.http.post(this.baseUrl+'/get-expense-lists-detail',body,this.getToken('get-expense-lists-detail')).catch(x => this.handleAuthError(x));
  }
}
