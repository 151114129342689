import { ChangeDetectorRef,Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  ProductFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedStatus: any;
  public selectedCategory: any;
  public expiryDate: any;
  public warrantyDate: any;
  public categoryList: any;
  public taxList:any;
  public selectedTaxIds:any;

  isUpdate:boolean = false;
  productId  = window.sessionStorage.getItem("product_id");

  filedata:any;
  identityurl: any = environment.frontUrl+'/assets/images/avatar.jpg';
  identitypdfurl: any = '';
  public identityfileObj: any;
  public fileExtension: any;
  fileExtensionMessage = false;

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getCategoryList();
    this.getTaxList();

    this.isUpdateRecord();
    this.productId = window.sessionStorage.getItem("product_id");
    this.formId = {id:this.productId};

    this.sharedService.getProductDetail(this.formId).subscribe(res=>{
      this.formValues = {
        product_name : res['data']['product_name'],
        product_version : res['data']['product_version'],
        original_price : res['data']['original_price'],
        discount_rate : res['data']['discount_rate'],
        product_photo : res['data']['product_photo']
      };
      this.selectedCategory = res['data']['category_id'];
      this.selectedStatus = res['data']['status'];
      this.expiryDate = res['data']['expiry_date'];
      this.warrantyDate = res['data']['warranty_date'];
      this.identityurl = res['data']['product_photo'];
      this.getSelectedTaxIds();

      this.ProductFormGroup.patchValue(this.formValues);

    });

    this.ProductFormGroup = this.fb.group({
      product_id : [this.productId, Validators.required],
      category_id : ['', Validators.required],
      product_name : ['', Validators.required],
      product_photo : ['', Validators.required],

      product_version : ['', Validators.required],
      original_price : ['', Validators.required],
      discount_rate : ['', Validators.required],
      expiry_date : ['', Validators.required],
      warranty_date : ['', Validators.required],
      tax_ids : ['', Validators.required],
      status:['', Validators.required]
    })
  }
  
  fileEvent(event) {
    const reader = new FileReader();
    this.fileExtensionMessage = false;
    if (!this.validateFile(event.target.files[0].name)) {
        this.fileExtensionMessage = true;
        return false;
    }
    if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        this.identityfileObj = file;
        reader.onload = (event: ProgressEvent) => {
            if (this.fileExtension === 'pdf'){
                this.identityurl = environment.frontUrl+'/assets/icons/category.png';
            }else{
                this.identityurl = (<FileReader>event.target).result;
            }
            this.ProductFormGroup.patchValue({
                identity_image: reader.result
            });
            // need to run CD since file load runs outside of zone
            this.cd.markForCheck();
        };
    }
  }

  validateFile(name: String) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'jpeg' || ext.toLowerCase() === 'gif') {
      this.fileExtensionMessage = false;
      this.fileExtension = ext.toLowerCase();
      return true;
    } else {
      return false;
    }
  }

  getCategoryList() {
    this.sharedService.getCategoryList().subscribe(resData => {
        if (resData['meta']['code'] == 1) {
            this.categoryList = resData['data'];
        }
    });
  }
  getSelectedTaxIds()
  {
    this.formId = {id:this.productId};
    this.sharedService.getSelectedTaxIds(this.formId).subscribe(resData => {
      if (resData['meta']['code'] == 1) {
          this.selectedTaxIds = resData['data'];
          console.log(this.selectedTaxIds);
      }
  });
  }

  getTaxList(){
    this.sharedService.getTaxList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
          this.taxList = resData['data'];
      }
    });
  }
  isUpdateRecord()
  {
    if(this.productId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.ProductFormGroup.controls; }

  addProductData(){
    const formData = new FormData();
    formData.append('product_photo', this.identityfileObj);
    formData.append('product_id', this.ProductFormGroup.value.product_id);
    formData.append('category_id', this.ProductFormGroup.value.category_id);
    formData.append('product_name', this.ProductFormGroup.value.product_name);
    formData.append('product_version', this.ProductFormGroup.value.product_version);
    formData.append('original_price', this.ProductFormGroup.value.original_price);
    formData.append('discount_rate', this.ProductFormGroup.value.discount_rate);
    formData.append('expiry_date', this.ProductFormGroup.value.expiry_date);
    formData.append('warranty_date', this.ProductFormGroup.value.warranty_date);
    formData.append('tax_ids', this.ProductFormGroup.value.tax_ids);
    formData.append('status', this.ProductFormGroup.value.status);

    console.log(formData);
    this.sharedService.addProduct(formData).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateProductData()
   {
      const formData = new FormData();
      formData.append('product_photo', this.identityfileObj);
      formData.append('product_id', this.ProductFormGroup.value.product_id);
      formData.append('category_id', this.ProductFormGroup.value.category_id);
      formData.append('product_name', this.ProductFormGroup.value.product_name);
      formData.append('product_version', this.ProductFormGroup.value.product_version);
      formData.append('original_price', this.ProductFormGroup.value.original_price);
      formData.append('discount_rate', this.ProductFormGroup.value.discount_rate);
      formData.append('expiry_date', this.ProductFormGroup.value.expiry_date);
      formData.append('warranty_date', this.ProductFormGroup.value.warranty_date);
      formData.append('tax_ids', this.ProductFormGroup.value.tax_ids);
      formData.append('status', this.ProductFormGroup.value.status);
     this.sharedService.editProduct(formData).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("product_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

}
