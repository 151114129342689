<div class="category-header">
    <div>Add New City</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="CityFormGroup">
        <input type="hidden" formControlName="city_id" />
        <div class="form-group add-category">
            <label>Country <span class="red">*</span></label>
            <select formControlName="country_id" id="country_id" class="form-control"
                [(ngModel)]="selectedCountry"
                [ngClass]="{ 'is-invalid': f.country_id.touched && f.country_id.errors }"
                (change)='onChangeCountry($event.target.value)'
                >
                <option value="0" selected>please select country</option>
                <option *ngFor="let item of countryList" value="{{item.id}}">
                    {{item.country_name}}
                </option>
            </select>
            <div *ngIf="f.country_id.touched && f.country_id.errors" class="invalid-feedback">
                <div *ngIf="f.country_id.errors.required">State Name is required</div>
            </div>
        </div>

        <div class="form-group add-category">
            <label>State <span class="red">*</span></label>
            <select formControlName="state_id" id="state_id" class="form-control"
                [(ngModel)]="selectedState"
                [ngClass]="{ 'is-invalid': f.state_id.touched && f.state_id.errors }">
                <option value="0" selected>please select state</option>
                <option *ngFor="let item of stateList" value="{{item.id}}">
                    {{item.state_name}}
                </option>
            </select>
            <div *ngIf="f.state_id.touched && f.state_id.errors" class="invalid-feedback">
                <div *ngIf="f.state_id.errors.required">State Name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>City Name <span class="red">*</span></label>
            <input class="" type="text" formControlName="city_name" class="form-control"
                [ngClass]="{ 'is-invalid': f.city_name.touched && f.city_name.errors }" />
            <div *ngIf="f.city_name.touched && f.city_name.errors" class="invalid-feedback">
                <div *ngIf="f.city_name.errors.required">City name is required</div>
            </div>
        </div>
        <div class="form-group add-category">
            <label>Status <span class="red">*</span></label>
            <select formControlName="status" class="form-control"
                [ngClass]="{ 'is-invalid': f.status.touched && f.status.errors }" [(ngModel)]="selectedStatus">
                <option value="1">Active</option>
                <option value="0">Inactive</option>
            </select>
            <div *ngIf="f.status.touched && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">Status is required</div>
            </div>
        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addCityData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updateCityData()">EDIT</button>

        </div>
    </form>
</div>