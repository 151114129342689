<section id="regestration" class="auth-height">
    <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <!-- <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                <img src="../../../../assets/images/register.png" alt="" class="img-fluid" width="350" height="230">
                            </div> -->
                            <div class="col-lg-12 col-md-12 px-4 py-3">
                               <div class="company-logo">
                                <h1 class="card-title mb-2">globalitrack.com </h1>
                                <p class="company-title">GCEC Company</p>
                               </div>
                              
                               <form class="form-group" [formGroup]="LoginFormGroup" >
                                    <fieldset class="form-group">
                                        <label for="basicInput">Username:</label>
                                        <input class="" type="text" formControlName="username" class="form-control" 
                                        [ngClass]="{ 'is-invalid': f.username.touched && f.username.errors }" />
                                        <div *ngIf="f.username.touched && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">Username is required</div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="form-group"> 
                                        <label for="basicInput">Password:</label>
                                        <input class="" type="password" formControlName="password" class="form-control" 
                                        [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" />
                                        <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </fieldset>
                                    
                                    <div class="sign-up-btn">
                                        <button type="submit" class="btn btn-primary" (click)="getLoginData()">Sign in</button>
                                    </div>
                                    <div class="sign-up-btn">
                                        <button type="button" class="forgot-link" (click)="openForgotPassword()">Forgot password? </button>
                                        <button type="button" class="forgot-link" (click)="createAccount()">Sign-up </button>
                                    
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  