import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import {DecimalPipe} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ThisReceiver } from '@angular/compiler';
import { CountryService } from 'src/app/expenses/country.service';
import { NgbdSortableHeader, SortEvent } from '../category/sortable.directive';
import { Country } from 'src/app/expenses/country';
import { AddNewExpenseComponent } from './add-new-expense/add-new-expense.component';
import { UserServiceService } from 'src/app/user-service.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss'],
  providers: [CountryService, DecimalPipe]
})
export class ExpensesComponent implements OnInit {


  countries$: any;
  total$: Observable<number>;
  formId : any;
  public selectedStatus: any;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private router: Router, private fb: FormBuilder,public service: CountryService, public dialog:MatDialog,private toastr: ToastrService, private sharedService : UserServiceService) {
    this.countries$ = service.countries$;
    this.total$ = service.total$;
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit(): void {
    
  }
  
  addNewExpense(){
    window.sessionStorage.setItem("expenses_id","");
    this.dialog.open(AddNewExpenseComponent,{
      disableClose:true
    })
  }

  updateExpenseData(id = null){
    console.log(id);
    window.sessionStorage.setItem("expenses_id",id);
    this.dialog.open(AddNewExpenseComponent,{
      disableClose:true
    })
  }

  deleteExpense(id = null){
    this.formId = {id : id};
    // @ts-ignore
    Swal.fire({
      title: 'Are you sure you want to delete this expense?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.sharedService.deleteExpense(this.formId).subscribe(res => {
            if(res['meta']['code'] == 1)
            {
                this.TypeSuccess("Record deleted successfully!");
                window.location.reload();
            }
            else
            {
              this.TypeError(res['meta']['message']);
            }
          }, err => {
              this.toastr.error(err, 'Error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

   
  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }
  
  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
