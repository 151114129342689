import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-add-sales-order',
  templateUrl: './add-sales-order.component.html',
  styleUrls: ['./add-sales-order.component.scss']
})
export class AddSalesOrderComponent implements OnInit {

  SalesOrderFormGroup : FormGroup;
  formId : any;
  public formValues: any = {};
  public selectedProduct: any;
  public selectedParty: any;
  public productList: any;
  public partyList: any;

  public productPrice: any;
  public totalPrice:any;
  
  public addresses: any[] = [{
    address: '',
    street: '',
    city: '',
    country: ''
  }];

  isUpdate:boolean = false;
  SalesOrderId  = window.sessionStorage.getItem("sales_order_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService,private sharedService : UserServiceService,public dialog:MatDialog) { }

  ngOnInit(): void {
    this.getProductList();
    this.getPartyList();
    this.isUpdateRecord();
    this.SalesOrderId = window.sessionStorage.getItem("sales_order_id");
    this.formId = {id:this.SalesOrderId};

    this.sharedService.getSalesOrderDetail(this.formId).subscribe(res=>{
      this.formValues = {
        sales_stocks : res['data']['sales_stocks'],
        final_price : res['data']['final_price'],
        total_price : res['data']['total_price']
      };
      this.selectedProduct = res['data']['product_id'];
      this.selectedParty = res['data']['party_id'];

      this.SalesOrderFormGroup.patchValue(this.formValues);

    });

    this.SalesOrderFormGroup = this.fb.group({
      sales_order_id : [this.SalesOrderId, Validators.required],
      product_id : ['', Validators.required],
      sales_stocks : ['', Validators.required],
      final_price : ['', Validators.required],
      party_id : ['', Validators.required],
      total_price : ['', Validators.required],

    })
  }
  removeAddress(i: number) {
    this.addresses.splice(i, 1);
  }

  addAddress() {
    this.addresses.push({
      id: this.addresses.length + 1,
      address: '',
      street: '',
      city: '',
      country: ''
    });
  }

  onChangeProduct(productId: number) {
    this.formId = { id: productId};
    this.sharedService.getProductDetail(this.formId).subscribe(resData => {
        if (resData['meta']['code'] == 1) {
          this.productPrice = resData['data']['final_price'];
          this.totalPrice = (this.formValues.sales_stocks * resData['data']['final_price']);
        }
    });
  }

  onChangeProductQuantity(productQty: number) {
    this.totalPrice = (productQty * this.productPrice);
  }

  getProductList() {
    this.sharedService.getProductList().subscribe(resData => {
        if (resData['meta']['code'] == 1) {
            this.productList = resData['data'];
        }
    });
  }

  getPartyList() {
    this.sharedService.getPartyList().subscribe(resData => {
        if (resData['meta']['code'] == 1) {
            this.partyList = resData['data'];
        }
    });
  }

  isUpdateRecord()
  {
    if(this.SalesOrderId){
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.SalesOrderFormGroup.controls; }

  addSalesOrderData(){
    console.log(this.addresses);
    this.sharedService.addSalesOrder(this.SalesOrderFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           this.TypeSuccess("Record inserted successfully!");
           this.dialog.closeAll();
           //window.location.reload();
       }
       else
       {
         this.TypeError(res['meta']['message']);
       }
     })
   }
 
   updateSalesOrderData()
   {
     this.sharedService.editSalesOrder(this.SalesOrderFormGroup.value).subscribe(res=>{
       if(res['meta']['code'] == 1)
       {
           window.sessionStorage.setItem("sales_order_id",null);
           this.TypeSuccess("Record updated successfully!");
           this.dialog.closeAll();
           window.location.reload();
       }
       else
       {
          this.TypeError(res['meta']['message']);
       }
     })
   }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

}
