import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailVerificationComponent } from './authentication/authentication/email-verification/email-verification.component';
import { RecoverPasswordComponent } from './authentication/authentication/recover-password/recover-password.component';
import { SetPasswordComponent } from './authentication/authentication/set-password/set-password.component';
import { SignupComponent } from './authentication/authentication/signup/signup.component';
import { LoginDeshboardComponent } from './authentication/login-deshboard/login-deshboard.component';
import { CategoryComponent } from './category/category.component';
import { CitiesComponent } from './cities/cities.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { ProductComponent } from './product/product.component';
import { StockComponent } from './stock/stock.component';
import { PartiesComponent } from './parties/parties.component';
import { TaxesComponent } from './taxes/taxes.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { ExpenseListsComponent } from './expense-lists/expense-lists.component';
import { CountriesComponent } from './countries/countries.component';
import { HomeDeshboardComponent } from './home-deshboard/home-deshboard.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesComponent } from './roles/roles.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { StatesComponent } from './states/states.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'account-login',
    pathMatch: 'full',
  },
  {
    path: 'account-login',
    component: LoginDeshboardComponent
  },
  {
    path: 'edit-profile',
    component: SignupComponent
  },

  {
    path: 'home-dashboard',
    component: HomeDeshboardComponent
  },
  {
    path: "forgot-password",
    component: RecoverPasswordComponent
  },
  {
    path: "set-password",
    component: SetPasswordComponent
  },
  {
    path: "sign-up",
    component: SignupComponent
  },
  {
    path: "email-verification",
    component: EmailVerificationComponent
  },
{
  path:"category-dashboard",
  component:CategoryComponent
},

{
  path:"products",
  component:ProductComponent
},

{
  path:"taxes",
  component:TaxesComponent
},

{
  path:"expenses",
  component:ExpensesComponent
},
{
  path:"expense-lists",
  component:ExpenseListsComponent
},
{
  path:"stocks",
  component:StockComponent
},

{
  path:"parties",
  component:PartiesComponent
},

{
  path:"purchase-orders",
  component:PurchaseOrderComponent
},

{
  path:"sales-orders",
  component:SalesOrderComponent
},

{
  path:"roles",
  component:RolesComponent
},
{
  path:'permissions',
  component:PermissionsComponent
},
{
  path:'states',
  component:StatesComponent
},
{
  path:'countries',
  component:CountriesComponent
},
{
  path:"cities",
  component:CitiesComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
