<div class="category-header">
    <div>Add Purchase Order</div>
    <button class="closebtn" matDialogClose>&times;</button>
</div>
<div class="add-category-form">
    <!-- <button class="closebtn" matDialogClose>&times;</button> -->
    <form [formGroup]="PurchaseOrderFormGroup">
        <input type="hidden" formControlName="purchase_order_id" />
        <div class="form-container">
            <div class="section1">
                <div class="form-group add-category">
                    <label>Party Name <span class="red">*</span></label>
                    <select formControlName="party_id" id="party_id" class="form-control"
                        [(ngModel)]="selectedParty" 
                        [ngClass]="{ 'is-invalid': f.party_id.touched && f.party_id.errors }">
                        <option value="0" selected>please select product</option>
                        <option *ngFor="let item of partyList" value="{{item.id}}">
                            {{item.full_name}}
                        </option>
                    </select>
                    <div *ngIf="f.party_id.touched && f.party_id.errors" class="invalid-feedback">
                        <div *ngIf="f.party_id.errors.required">Party Name is required</div>
                    </div>
                </div>

                <div class="form-group add-category">
                    <label>Product <span class="red">*</span></label>
                    <select formControlName="product_id" id="product_id" class="form-control"
                        [(ngModel)]="selectedProduct" (change)="onChangeProduct($event.target.value)"
                        [ngClass]="{ 'is-invalid': f.product_id.touched && f.product_id.errors }">
                        <option value="0" selected>please select product</option>
                        <option *ngFor="let item of productList" value="{{item.id}}">
                            {{item.product_name}}
                        </option>
                    </select>
                    <div *ngIf="f.product_id.touched && f.product_id.errors" class="invalid-feedback">
                        <div *ngIf="f.product_id.errors.required">Product Name is required</div>
                    </div>
                </div>
                <div class="form-group add-category">
                    <label>Purchase Quantity<span class="red">*</span></label>
                    <input type="text" placeholder="Purchase Quantity" formControlName="purchase_stocks" class="form-control"
                        [ngClass]="{ 'is-invalid': f.purchase_stocks.touched && f.purchase_stocks.errors }"  (keyup)="onChangeProductQuantity($event.target.value)"/>
                    <div *ngIf="f.purchase_stocks.touched && f.purchase_stocks.errors" class="invalid-feedback">
                        <div *ngIf="f.purchase_stocks.errors.required">Purchase quantity is required</div>
                    </div>
                </div>
                

            </div>
            <div class="section2">
                <div class="form-group add-category" style="margin-bottom: 55px;">
                    <label><span class="red"></span></label>
                   
                </div>

                <div class="form-group add-category">
                    <label>Product Price<span class="red">*</span></label>
                    <input type="text" placeholder="Product Price" formControlName="final_price" class="form-control"
                        [ngClass]="{ 'is-invalid': f.final_price.touched && f.final_price.errors }"  [(ngModel)]="productPrice" />
                    <div *ngIf="f.final_price.touched && f.final_price.errors" class="invalid-feedback">
                        <div *ngIf="f.final_price.errors.required">Product price name is required</div>
                    </div>
                </div>

                <div class="form-group add-category">
                    <label>Total Price<span class="red">*</span></label>
                    <input type="text" placeholder="Total Price" formControlName="total_price" class="form-control"
                        [ngClass]="{ 'is-invalid': f.total_price.touched && f.total_price.errors }" [(ngModel)]="totalPrice" />
                    <div *ngIf="f.total_price.touched && f.total_price.errors" class="invalid-feedback">
                        <div *ngIf="f.total_price.errors.required">Total price name is required</div>
                    </div>
                </div>

            </div>
           
          

        </div>
        <div class="row form-group submit-btn">
            <button *ngIf="!isUpdate" type="submit" class="btn btn-primary" (click)="addPurchaseOrderData()">ADD</button>
            <button *ngIf="isUpdate" type="submit" class="btn btn-primary" (click)="updatePurchaseOrderData()">EDIT</button>

        </div>
    </form>
</div>