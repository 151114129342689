import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeDeshboardComponent } from './home-deshboard/home-deshboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthenticationModule } from './authentication/authentication/authentication.module';
import { HttpClientModule } from '@angular/common/http';
import { CategoryComponent } from './category/category.component';
import { ProductComponent } from './product/product.component';
import { StockComponent } from './stock/stock.component';
import { PartiesComponent } from './parties/parties.component';
import { TaxesComponent } from './taxes/taxes.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { ExpenseListsComponent } from './expense-lists/expense-lists.component';


import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';

import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { AddCategoryComponent } from './category/add-category/add-category.component';
import { AddProductComponent } from './product/add-product/add-product.component';
import { AddStockComponent } from './stock/add-stock/add-stock.component';
import { AddPurchaseOrderComponent } from './purchase-order/add-purchase-order/add-purchase-order.component';
import { AddSalesOrderComponent } from './sales-order/add-sales-order/add-sales-order.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { RolesComponent } from './roles/roles.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { CitiesComponent } from './cities/cities.component';
import { StatesComponent } from './states/states.component';
import { CountriesComponent } from './countries/countries.component';
import { AddPermissionsComponent } from './permissions/add-permissions/add-permissions.component';
import { AddNewRoleComponent } from './roles/add-new-role/add-new-role.component';
import { AddNewCityComponent } from './cities/add-new-city/add-new-city.component';
import { AddNewStateComponent } from './states/add-new-state/add-new-state.component';
import { AddNewConturyComponent } from './countries/add-new-contury/add-new-contury.component';
import { AddNewPartyComponent } from './parties/add-new-party/add-new-party.component';
import { AddNewTaxComponent } from './taxes/add-new-tax/add-new-tax.component';
import { AddNewExpenseComponent } from './expenses/add-new-expense/add-new-expense.component';
import { AddNewExpenseListsComponent } from './expense-lists/add-new-expense-lists/add-new-expense-lists.component';
import { HeaderComponent } from './header/header.component';
import {MatMenuModule} from '@angular/material/menu';
import { SharedModule } from './shared/shared.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeDeshboardComponent,
	  CategoryComponent,
	  ProductComponent,
	  StockComponent,
    PartiesComponent,
    TaxesComponent,
    ExpensesComponent,
    ExpenseListsComponent,
    PurchaseOrderComponent,
    SalesOrderComponent,
    SideNavBarComponent,
    AddCategoryComponent,
    AddProductComponent,
    AddNewPartyComponent,
    AddNewTaxComponent,
    AddNewExpenseComponent,
    AddNewExpenseListsComponent,
    AddStockComponent,
    AddPurchaseOrderComponent,
    AddSalesOrderComponent,
    RolesComponent,
    PermissionsComponent,
    CitiesComponent,
    StatesComponent,
    CountriesComponent,
    AddPermissionsComponent,
    AddNewRoleComponent,
    AddNewCityComponent,
    AddNewStateComponent,
    AddNewConturyComponent
    //HeaderComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatMenuModule,
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
