import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss']
})
export class AddStockComponent implements OnInit {

  StockFormGroup: FormGroup;
  formId: any;
  public formValues: any = {};
  public selectedProduct: any;
  public productList: any;

  isUpdate: boolean = false;
  stockId = window.sessionStorage.getItem("product_id");

  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private sharedService: UserServiceService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getProductList();
    this.isUpdateRecord();
    this.stockId = window.sessionStorage.getItem("product_id");
    this.formId = { id: this.stockId };

    this.sharedService.getProductDetail(this.formId).subscribe(res => {
      this.formValues = {
        available_stocks: res['data']['available_stocks'],
        sales_stocks: res['data']['sales_stocks'],
        purchase_stocks: res['data']['purchase_stocks'],

      };
      this.selectedProduct = res['data']['product_id'];

      this.StockFormGroup.patchValue(this.formValues);

    });

    this.StockFormGroup = this.fb.group({
      stock_id: [this.stockId, Validators.required],
      product_id: ['', Validators.required],
      available_stocks: ['', Validators.required],
      sales_stocks: ['', Validators.required],
      purchase_stocks: ['', Validators.required],
    })
  }

  getProductList() {
    this.sharedService.getProductList().subscribe(resData => {
      if (resData['meta']['code'] == 1) {
        this.productList = resData['data'];
      }
    });
  }

  isUpdateRecord() {
    if (this.stockId) {
      this.isUpdate = true;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.StockFormGroup.controls; }

  addStockData() {
    this.sharedService.addStock(this.StockFormGroup.value).subscribe(res => {
      if (res['meta']['code'] == 1) {
        this.TypeSuccess("Record inserted successfully!");
        this.dialog.closeAll();
        window.location.reload();
      }
      else {
        this.TypeError(res['meta']['message']);
      }
    })
  }

  updateStockData() {
    this.sharedService.editStock(this.StockFormGroup.value).subscribe(res => {
      if (res['meta']['code'] == 1) {
        window.sessionStorage.setItem("stock_id", null);
        this.TypeSuccess("Record updated successfully!");
        this.dialog.closeAll();
        window.location.reload();
      }
      else {
        this.TypeError(res['meta']['message']);
      }
    })
  }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }

  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }

}
