import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AddCategoryComponent } from '../category/add-category/add-category.component';
import { Country } from '../category/country';
import { CountryService } from '../category/country.service';
import { NgbdSortableHeader, SortEvent } from '../category/sortable.directive';
import { AddPermissionsComponent } from './add-permissions/add-permissions.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  providers: [CountryService, DecimalPipe]
})
export class PermissionsComponent implements OnInit {



  countries$: Observable<Country[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: CountryService, public dialog:MatDialog) {
    this.countries$ = service.countries$;
    this.total$ = service.total$;
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnInit(): void {
  }
  addNewPermission(){
    this.dialog.open(AddPermissionsComponent,{
      disableClose:true
    })
  }

}
