import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrServiceService } from 'src/app/toastr-service.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { UserServiceService } from 'src/app/user-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  RecoverFormGroup: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private sharedService: UserServiceService) { }

  result = "";
  color = '';

  ngOnInit(): void {
    this.RecoverFormGroup = this.fb.group({
      email: ['', Validators.required]
    })
  }

  get f() { return this.RecoverFormGroup.controls; }

  openLoginPage() {
    this.router.navigate(["account-login"])
  }

  forgotPassword(){
    this.sharedService.recoverPassword(this.RecoverFormGroup.value).subscribe(res => {
      if (res['meta']['code'] == 1) {
        this.TypeSuccess("Check your mail..");
        window.location.href = environment.frontUrl;
      }
      else {
        this.TypeError(res['meta']['message']); 
      }
    })

  }

  currentToast = null;

  //Success Type
  TypeSuccess(msg = null) {
    this.toastr.success(msg);
  }
  
  // Error Type
  TypeError(msg = null) {
    this.toastr.error(msg);
  }
}
